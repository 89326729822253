import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { TwoColumnWithHeading } from 'templates';
import { IMemberRoles } from 'src/types';

// Types
interface IProps {
  data: {
    memberRoles: {
      nodes: {
        data: IMemberRoles;
      }[];
    };
  };
}

const OurTeamPageTemplate = (props: IProps): ReactElement => {
  const { nodes } = props?.data?.memberRoles;

  return (
    <SEOContent>
      <PageLayout>
        <TwoColumnWithHeading nodes={nodes} />
      </PageLayout>
    </SEOContent>
  );
};

export default OurTeamPageTemplate;

export const query = graphql`
  query {
    memberRoles: allAirtable(
      filter: { table: { eq: "member-roles" } }
      sort: { fields: data___role_id }
    ) {
      nodes {
        data {
          members: Members {
            data {
              member_slug
              full_name
              bio
              email
              profile_picture {
                thumbnails {
                  large {
                    url
                  }
                }
              }
              organization
              twitter
            }
          }
          role
        }
      }
    }
  }
`;
